import { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { SxProps, Table as MuiTable, Theme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { ColumnsType, TSearchFilters } from 'src/components/ui/new-table/types';

import { Empty } from 'src/components/ui/empty';
import { Typography } from 'src/components/ui/index';

import styles from './new-table-desktop.module.scss';
import { Header, Row, TablePagination } from './components';

interface NewTableProps<RecordType> {
  loading?: boolean;
  className?: string;
  columns: ColumnsType<RecordType>;
  data?: RecordType[];
  sx?: SxProps<Theme>;
  onSelected?: (select: RecordType[]) => void;
  onSelectedAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  selected: RecordType[];
  page: number;
  totalCountItems?: number;
  countPerPage: number;
  onPageSelected?: (page: number) => void;
  emptyText?: ReactNode;
  onFilter?: (filters: TSearchFilters<RecordType>) => void;
}

export const NewTableDesktop = <RecordType extends { key: number | string }>({
  loading,
  className,
  columns,
  data,
  sx,
  onSelected,
  onSelectedAllClick,
  selected,
  page,
  totalCountItems,
  countPerPage,
  onPageSelected,
  emptyText,
  onFilter,
}: NewTableProps<RecordType>) =>
  data?.length ? (
    <div
      className={classNames(
        className,
        styles.wrapper,
        loading && styles.loading
      )}>
      <div className={classNames(styles.wrapperTable)}>
        <MuiTable style={{ tableLayout: 'fixed' }} className={styles.table}>
          <Header
            columns={columns}
            rowCount={data.length}
            selected={selected}
            onSelectAllClick={onSelectedAllClick}
            onFilter={onFilter}
          />
          <TableBody sx={sx}>
            {data.map((row) => (
              <Row<RecordType>
                key={row.key}
                columns={columns}
                record={row}
                selected={selected}
                setSelected={onSelected}
              />
            ))}
          </TableBody>
        </MuiTable>
      </div>
      {!!totalCountItems && totalCountItems > countPerPage && (
        <TablePagination
          itemsCount={totalCountItems}
          countPerPage={countPerPage}
          handleChange={onPageSelected}
          page={page}
        />
      )}
    </div>
  ) : (
    <Empty>
      {emptyText ? (
        <div>{emptyText}</div>
      ) : (
        <>
          <Typography variant="h2">Ничего не найдено</Typography>
          <Typography fontSize="16" align="center">
            Попробуйте задать другие фильтры <br /> и повторите запрос.
          </Typography>
        </>
      )}
    </Empty>
  );
