export const FILE_UPLOAD_ERRORS = {
  max_count: {
    title: 'Превышен лимит загрузок',
    body: 'Максимальное число файлов: 10',
  },

  max_file_size: {
    title: 'Превышен объем файла',
    body: 'Объем файла не должен превышать 30 мб',
  },

  max_file_sum_size: {
    title: 'Превышен объем файлов',
    body: 'Общий объем не должен превышать 30 мб',
  },
};

export const MAX_FILES_COUNT = 10;
export const MAX_FILE_SIZE = 3e7;
export const MAX_FILE_SUM_SIZE = 3e7;