import { Tooltip, Box } from 'src/components/ui';
import { FC, PropsWithChildren } from 'react';
import {
  Approve,
  ExclamationIcon,
  ExpertBarWarningIcon,
  InfoHelpIcon,
  WarningSimple,
} from 'src/assets/icons';
import cn from 'classnames';
import { BoxProps } from '@mui/material';
import styles from './hint-bar.module.scss';

export enum HelpExpertStatuses {
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export interface IHintBarProps extends BoxProps {
  status?: null | HelpExpertStatuses;
  tooltip?: React.ReactNode;
  after?: React.ReactNode;
}

const renderIcon = (status: HelpExpertStatuses | null) => {
  switch (status) {
    case HelpExpertStatuses.APPROVED: {
      return <Approve width={24} height={24} />;
    }
    case HelpExpertStatuses.PENDING: {
      return <ExpertBarWarningIcon width={24} height={24} />;
    }
    case HelpExpertStatuses.REJECTED: {
      return <ExclamationIcon width={24} height={24} />;
    }
    default: {
      return <InfoHelpIcon />;
    }
  }
};

export const HintBar: FC<PropsWithChildren<IHintBarProps>> = ({
  children,
  tooltip,
  status = null,
  after,
  ...props
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    className={cn(styles.wrapper, {
      [styles.pending]: status === HelpExpertStatuses.PENDING,
      [styles.rejected]: status === HelpExpertStatuses.REJECTED,
      [styles.approved]: status === HelpExpertStatuses.APPROVED,
    })}
    {...props}>
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      className={styles.barContent}>
      <Box className={styles.icon}>
        {tooltip ? (
          <Tooltip title={tooltip}>{renderIcon(status)}</Tooltip>
        ) : (
          renderIcon(status)
        )}
      </Box>
      {children}
      <Box flex={1} display="flex" justifyContent="end">
        {after}
      </Box>
    </Box>
  </Box>
);
