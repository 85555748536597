import { Stack, Typography } from '@mui/material';
import { Button } from 'src/components/ui';
import { AddTableItemIcon } from 'src/assets/icons';
import { theme } from 'src/theme';

interface HeaderProps {
  totalCount?: number;
  onAddButtonClick?: () => void;
}

export const Header = ({ totalCount, onAddButtonClick }: HeaderProps) => (
  <Stack direction="row" justifyContent="space-between" flex={1}>
    <Stack direction="column" gap="8px">
      <Typography fontSize="20px" fontWeight="500">
        Все заявки компании
      </Typography>
      <Typography fontSize="12px" color={theme.palette.text.secondary}>
        {`Всего заявок: ${totalCount ?? ''}`}
      </Typography>
    </Stack>
  </Stack>
);
