// TODO: Сделать однообразно, чтобы на каждый тип коммуникации не дублировать
import {
  IEmailMessage,
  INote,
  ITelephonyCall,
  IMessage,
} from 'src/models/communications';
import { TEvent } from 'src/models/admin';
import {
  isCallItem,
  isEmailItem,
  isMessageItem,
  isNoteItem,
} from './get-communication-type';

export const checkIfLastEmail = (
  message: IEmailMessage,
  nextCommunicationItem:
    | IEmailMessage
    | ITelephonyCall
    | TEvent[]
    | INote
    | IMessage
) => {
  if (!nextCommunicationItem || !isEmailItem(nextCommunicationItem)) {
    return true;
  }

  return message.from.id !== nextCommunicationItem?.from?.id;
};

export const checkIfLastCall = (
  call: ITelephonyCall,
  nextCommunicationItem:
    | IEmailMessage
    | ITelephonyCall
    | TEvent[]
    | INote
    | IMessage
) => {
  if (!nextCommunicationItem || !isCallItem(nextCommunicationItem)) {
    return true;
  }

  return call.from !== nextCommunicationItem?.from;
};

export const checkIfLastMessage = (
  message: IMessage,
  nextCommunicationItem:
    | IEmailMessage
    | ITelephonyCall
    | TEvent[]
    | INote
    | IMessage
) => {
  if (!nextCommunicationItem || !isMessageItem(nextCommunicationItem)) {
    return true;
  }

  return message.owner.id !== nextCommunicationItem?.owner?.id;
};

export const checkIfLastNote = (
  note: INote,
  nextCommunicationItem:
    | IEmailMessage
    | ITelephonyCall
    | TEvent[]
    | INote
    | IMessage
) => {
  if (!nextCommunicationItem || !isNoteItem(nextCommunicationItem)) {
    return true;
  }

  return note.owner.id !== nextCommunicationItem?.owner?.id;
};
