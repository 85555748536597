import { FC, useEffect, useRef, useState } from 'react';
import { SuccessNotifications } from 'src/assets/icons';
import { Alert, FixedHeader } from 'src/components/ui';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ManagerCard, MediaDrawerButton } from 'src/components/widgets';
import { PERMISSIONS } from 'src/constants';
import {
  BusinessRequestMode,
  IContractorBusinessRequest,
  IContractorRequest,
  IManagerBusinessRequest,
  IStatusHistory,
  TVariantBusinessRequest,
} from 'src/models';
import { RolePermissionService } from 'src/services';
import { Stepper } from '../stepper';
import {
  AuctionInfo,
  CloseRequest,
  ContractorRequestStatus,
  NameRequest,
  RebiddingInfo,
  RefusalInfo,
  RefusalRequest,
  RejectApplication,
  RequestNumber,
  RestoreRequest,
  Tabs,
  TakeWorkBusinessRequest,
  TakeWorkContractorRequest,
} from './components';
import { ContractorBusinessRequestStatus } from './components/contractor-business-request-status';
import { RemoveRequest } from './components/remove-request';

import styles from './header.module.scss';

interface IHeaderProps {
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory | undefined;
  mode: BusinessRequestMode;
  statusHistoryLoading: boolean;
  statusHistoryError: any;
  manager?: {
    id: number;
    login: string;
    lastName: string;
    firstName: string;
    middleName: string;
    position: string;
    contactInfoDto: {
      phones: TPhoneInputValue[];
    };
  };
  onClick: () => void;
}

export const Header: FC<IHeaderProps> = ({
  businessRequest,
  statusHistory,
  mode,
  statusHistoryLoading,
  statusHistoryError,
  manager,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [reqInfoWidth, setReqInfoWidth] = useState('');

  useEffect(() => {
    const width = containerRef?.current?.offsetWidth
      ? containerRef.current.offsetWidth + 10
      : 10;

    setReqInfoWidth(`calc(100% - ${width}px)`);
  }, [setReqInfoWidth]);

  return (
    <>
      <FixedHeader onClick={onClick} className={styles.headerWrapper}>
        <div className={styles.header}>
          <div
            className={styles.requestInfo}
            style={{ maxWidth: reqInfoWidth }}>
            <RequestNumber
              businessRequest={businessRequest}
              manager={manager}
            />
            <NameRequest businessRequest={businessRequest} />
          </div>
          <div className={styles.container} ref={containerRef}>
            <div className={styles.containerRequestInfo}>
              {mode === BusinessRequestMode.CONTRACTOR_ALL && (
                <ContractorBusinessRequestStatus
                  contractorRequest={
                    businessRequest as IContractorBusinessRequest
                  }
                />
              )}

              {mode === BusinessRequestMode.MANAGER &&
                (businessRequest as IManagerBusinessRequest).type ===
                  'EXPECTED' && <Alert content="На перспективу" />}

              {mode !== BusinessRequestMode.CONTRACTOR_ALL && (
                <div className={styles.containerRefusalInfo}>
                  <RefusalRequest
                    mode={mode}
                    businessRequest={businessRequest}
                  />

                  <RefusalInfo businessRequest={businessRequest} />

                  {(mode === BusinessRequestMode.MANAGER ||
                    mode === BusinessRequestMode.CONTRACTOR) && (
                    <>
                      <AuctionInfo
                        businessRequest={
                          businessRequest as
                            | IManagerBusinessRequest
                            | IContractorRequest
                        }
                      />
                      <RebiddingInfo
                        businessRequest={
                          businessRequest as
                            | IManagerBusinessRequest
                            | IContractorRequest
                        }
                      />
                    </>
                  )}

                  {mode === BusinessRequestMode.CONTRACTOR && statusHistory && (
                    <ContractorRequestStatus
                      contractorRequest={businessRequest as IContractorRequest}
                      statusHistory={statusHistory}
                    />
                  )}
                </div>
              )}
            </div>

            <div className={styles.containerButtons}>
              <MediaDrawerButton
                title="История статусов"
                icon={<SuccessNotifications />}>
                <Stepper
                  statusHistory={statusHistory}
                  isLoading={statusHistoryLoading}
                  error={statusHistoryError}
                  withCollapse={false}
                />
                {RolePermissionService.can(PERMISSIONS.VIEW_MANAGER_CARD) &&
                  manager && <ManagerCard manager={manager} />}
              </MediaDrawerButton>

              {mode === BusinessRequestMode.CONTRACTOR_ALL ? (
                <TakeWorkBusinessRequest
                  businessRequest={
                    businessRequest as IContractorBusinessRequest
                  }
                />
              ) : (
                <>
                  {mode === BusinessRequestMode.CONTRACTOR && (
                    <TakeWorkContractorRequest
                      contractorRequest={businessRequest as IContractorRequest}
                    />
                  )}
                  <RejectApplication
                    mode={mode}
                    businessRequest={businessRequest}
                  />
                </>
              )}

              {(mode === BusinessRequestMode.MANAGER ||
                mode === BusinessRequestMode.CONTRACTOR) &&
                statusHistory && (
                  <RestoreRequest
                    businessRequest={businessRequest}
                    statusHistory={statusHistory}
                    contractorMode={mode === BusinessRequestMode.CONTRACTOR}
                  />
                )}
              {RolePermissionService.can(PERMISSIONS.CLOSE_REQUEST) && (
                <CloseRequest businessRequest={businessRequest} />
              )}
              {RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL) &&
                !!businessRequest && (
                  <RemoveRequest businessRequest={businessRequest} />
                )}
            </div>
          </div>
        </div>
      </FixedHeader>

      <Tabs
        mode={mode}
        statusHistory={statusHistory}
        businessRequest={businessRequest}
      />
    </>
  );
};
