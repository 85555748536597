import { useForm } from 'react-hook-form';
import {
  IUpdateCompanyRequest,
  useUpdateCompanyMutation,
} from 'src/api/company';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ICompany } from 'src/models';
import { CompanyTypes, ISegment } from 'src/models/company';
import { getErrorMessage } from 'src/store/utils';

interface ICompanyForm {
  amocrmId: null | number;
  organizationName: string;
  organizationFullName: string;
  websiteLink: string;
  organizationDescription: string;
  companyType?: string;
  headFio?: string;
  headPosition?: string;
  email?: string;
  phone?: TPhoneInputValue;
  segment?: ISegment;
  esk?: number;
}

export const useCompanyDetailsForm = ({
  companyData,
  companyType,
  onSuccess,
}: {
  companyData: ICompany;
  companyType: CompanyTypes;
  onSuccess?: VoidFunction;
}) => {
  const [updateCompanyDetails] = useUpdateCompanyMutation();

  const form = useForm<ICompanyForm>({
    defaultValues: {
      amocrmId: companyData.organizationDetails?.amocrmId || null,
      organizationName: companyData.organizationDetails?.organizationName || '',
      organizationFullName:
        companyData.organizationDetails?.organizationFullName || '',
      websiteLink: companyData.organizationDetails?.websiteLink || '',
      organizationDescription:
        companyData.organizationDetails?.organizationDescription || '',
      companyType: companyData.customerType,
      headFio: companyData.organizationDetails?.headFio,
      headPosition: companyData.organizationDetails?.headPosition,
      phone: companyData.organizationDetails?.phone
        ? {
            type: 'MOBILE',
            number: companyData.organizationDetails!.phone,
          }
        : undefined,
      email: companyData.organizationDetails?.email,
      segment:
        companyData?.segmentId && companyData?.segmentName
          ? { id: companyData.segmentId, name: companyData.segmentName }
          : undefined,
      esk: companyData?.esk || undefined,
    },
  });

  const handleSubmit = async (formData: ICompanyForm) => {
    try {
      const params = {
        organizationId: companyData.id,
        body: {
          roles: companyData.roles,
          technicalOpportunitiesCategories:
            companyData.technicalOpportunitiesCategories,
          organizationBankDetails: companyData.organizationBankDetails || {},
          inn: companyData.inn,
          organizationDetails: {
            ...companyData.organizationDetails,
            ...formData,
            phone: formData.phone?.number,
            amocrmId: formData.amocrmId || undefined,
          },
          comment: companyData.comment || '',
          customerType: formData.companyType,
        },
      } as IUpdateCompanyRequest;

      if (companyType === CompanyTypes.CONTRACTOR) {
        params.body.segmentId = formData.segment?.id;
        params.body.esk = formData.esk;
      }

      await updateCompanyDetails(params).unwrap();

      onSuccess?.();
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e, 'Произошла ошибка при обновлении компании'),
      });
    }
  };

  const resetForm = () => {
    form.reset({
      amocrmId: companyData.organizationDetails?.amocrmId || null,
      organizationFullName:
        companyData.organizationDetails?.organizationFullName || '',
      organizationName: companyData.organizationDetails?.organizationName || '',
      websiteLink: companyData.organizationDetails?.websiteLink || '',
      segment:
        companyData?.segmentId && companyData?.segmentName
          ? { id: companyData.segmentId, name: companyData.segmentName }
          : undefined,
      esk: companyData?.esk || undefined,
    });
  };

  return { handleSubmit, resetForm, form };
};
