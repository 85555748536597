import { getUserInitials, getUserInitialsFromFullName } from 'src/utils';
import styles from './avatar.module.scss';

interface IAvatarProps {
  userInfo?: {
    firstName: string;
    lastName: string;
  };
  userFullName?: string;
  className?: string;
  borderColor?: string;
}

export const Avatar = ({
  userInfo,
  userFullName,
  className,
  borderColor,
}: IAvatarProps) => {
  const initials = userInfo
    ? getUserInitials(userInfo)
    : getUserInitialsFromFullName(userFullName || '');

  const initialsWithoutSpaces = initials?.replace(/\s+/g, '');

  return (
    <div
      className={className}
      style={
        borderColor
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `2px solid ${borderColor}`,
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }
          : {}
      }>
      <div
        className={styles.avatar}
        style={borderColor ? { width: '32px', height: '32px' } : {}}>
        {initialsWithoutSpaces || ''}
      </div>
    </div>
  );
};
