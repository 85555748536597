import { TContact } from 'src/components/pages/company/contacts/types';
import { KamStatusCode } from 'src/models/bid';
import {
  ContractorRequestStatusForKam,
  ContractorRequestStatusForKamPlus,
  ISpecificRole,
} from 'src/models/bids-details';
import { ICompany } from 'src/models/company';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ContractStatuses } from 'src/models/contract';

type TRequest = {
  totalCount: number;
  specificationSignedCount: number;
  inProgressCount: number;
  totalCost: number;
};

type TOrder = {
  fulfilled?: number;
  inWork?: number;
  sum: number;
};

export enum ContractorStatusForKam {
  CONTRACTOR_WITHOUT_CONTACT = 'CONTRACTOR_WITHOUT_CONTACT',
  NEW_CONTRACTOR = 'NEW_CONTRACTOR',
  PROPS_UPDATED = 'PROPS_UPDATED',
  ESK_ACQUIRED = 'ESK_ACQUIRED',
  SOB_CHECKUP_COMPLETED = 'SOB_CHECKUP_COMPLETED',
  SIGNING_APPROVED = 'SIGNING_APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACTOR_SIGNED = 'CONTRACTOR_SIGNED',
  REFUSED = 'REFUSED',
}

export const ContractorStatusForKamDisplayName: Record<
  ContractorStatusForKam,
  string
> = {
  CONTRACTOR_WITHOUT_CONTACT: 'Исполнитель без контакта',
  NEW_CONTRACTOR: 'Новый исполнитель',
  PROPS_UPDATED: 'Информация об исполнителе получена',
  ESK_ACQUIRED: 'ЕСК получен',
  SOB_CHECKUP_COMPLETED: 'Проверка СОБ пройдена',
  SIGNING_APPROVED: 'Подключение исполнителя согласовано',
  CONTRACT_SENT: 'Проект договора отправлен',
  CONTRACT_SIGNED: 'Договор подписан',
  CONTRACTOR_SIGNED: 'Исполнитель подключен',
  REFUSED: 'Подключение отклонено',
};

export const ContractorStatusForKamDescription: Record<
  ContractorStatusForKam,
  string
> = {
  CONTRACTOR_WITHOUT_CONTACT:
    'Новый исполнитель без контактных лиц. Прикрепите контакт к исполнителю, чтобы он получил доступ к личному кабинету.',
  NEW_CONTRACTOR:
    'Ожидаем предоставление информации о компании от исполнителя. Проверьте данные по заполнении и запросите ЕСК.',
  PROPS_UPDATED:
    'Необходимо заполнить единый номер контрагента компании перед отправкой на проверку СОБ.',
  ESK_ACQUIRED:
    'Отправьте письмо в СОБ для проверки исполнителя. Внесите решение и комментарий СОБ для продолжения подключения.',
  SOB_CHECKUP_COMPLETED: 'Согласуйте подключение исполнителя с руководителем',
  SIGNING_APPROVED:
    'Подключение согласовано. Отправьте исполнителю проект договора или загрузите подписанный договор.',
  CONTRACT_SENT:
    'Получите подписанный договор со стороны исполнителя и загрузите финальную версию.',
  CONTRACT_SIGNED:
    'Финальная версия договора подписана. Загрузите презентацию и допустите исполнителя к работе.',
  CONTRACTOR_SIGNED: 'Исполнитель успешно подключен к платформе.',
  REFUSED: 'Принято решение не подключать исполнителя к платформе.',
};

export enum ContractorStatusForContractor {
  EXPECTING_PROPS = 'EXPECTING_PROPS',
  PROPS_UPDATED = 'PROPS_UPDATED',
  SOB_CHECKUP = 'SOB_CHECKUP',
  SOB_CHECKUP_COMPLETED = 'SOB_CHECKUP_COMPLETED',
  CONTRACT_RECIEVED = 'CONTRACT_RECIEVED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACTOR_SIGNED = 'CONTRACTOR_SIGNED',
  REFUSED = 'REFUSED',
}

export const ContractorStatusForContractorDisplayName: Record<
  ContractorStatusForContractor,
  string
> = {
  EXPECTING_PROPS: 'Предоставление информации',
  PROPS_UPDATED: 'Информация отправлена',
  SOB_CHECKUP: 'Проверка СОБ',
  SOB_CHECKUP_COMPLETED: 'Проверка СОБ пройдена',
  CONTRACT_RECIEVED: 'Подписание договора',
  CONTRACT_SIGNED: 'Договор подписан',
  CONTRACTOR_SIGNED: 'Подключено завершено',
  REFUSED: 'Подключение отклонено',
};

export const ContractorStatusForContractorDescription: Record<
  ContractorStatusForContractor,
  string
> = {
  EXPECTING_PROPS:
    'Заполните обязательную информацию о своей компании для продолжения подключения.',
  PROPS_UPDATED:
    'Ответственный менеджер проверяет предоставленную информацию. Ожидайте обратную связь.',
  SOB_CHECKUP: 'Ваша заявка передана на проверку в службу безопасности.',
  SOB_CHECKUP_COMPLETED:
    'В ближайшее время менеджер вышлет Вам договор для подписания.',
  CONTRACT_RECIEVED: 'Ознакомьтесь с договором и загрузите подписанную копию.',
  CONTRACT_SIGNED: 'Договор подписан.',
  CONTRACTOR_SIGNED:
    'Вы успешно подключены к платформе COMETAL! Теперь Вам доступен раздел заявок.',
  REFUSED: 'Ваша заявка на подключение была отклонена.',
};

export interface IContractorStatusBlock<StatusType> {
  id: number;
  contractorSigningId: number;
  status: StatusType;
  createdAt: string;
  displayName: string;
  description: string;
}

export interface IContractorsSigningKanbanParams {
  onlyMine: boolean;
  status: string;
}
interface IContractorSigningAssignment {
  id: number;
  assignedRole: string;
  assignmentStatus: string;
  createdAt: string;
  updatedAt: string;
}

export interface IContractorsSigningKanbanItem {
  id: number;
  organizationId: number;
  organizationName: string;
  region: string | null;
  number: number;
  statusForKam: ContractorRequestStatusForKamPlus;
  fio: string;
  phoneNumber: string;
  position: string | null;
  kamPlusFio: string;
  contractorSigningAssignments?: IContractorSigningAssignment[];
}

export type IContractorsSigningKanbanGroupedItem = {
  [key in ContractorRequestStatusForKamPlus]: IContractorsSigningKanbanItem[];
};

export interface IContractorsSigningKanban {
  totalCount: number;
  countByStatus: {
    activeCount: number;
    lockedCount: number;
  } | null;

  groupedItems: IContractorsSigningKanbanGroupedItem;
}

export interface IContractor {
  id: number;
  organizationName?: string;
  contractStatus: ContractStatuses;
  createdAt: string;
  lastActivity: string;
  mainContact?: TContact;
  plainContactsCount: number;
  organizationStatus: ICompany['organizationStatus'];
  contactInfoDto?: Array<TPhoneInputValue>;
  region?: string;
  contractorRequestsInfo: TRequest;
  businessRequestsCount: number;
  orders?: TOrder;
  technicalAuditPassed: boolean;
  rating?: number;
  documents?: {
    documentId: number;
    documentName: string;
    entityId: number;
  }[];
}

type TMainContactLite = {
  firstName: string;
  lastName: string;
  middleName: string;
  position: string;
  mainPhone: TPhoneInputValue;
};

export type TLiteOrganization = Omit<
  IContractor,
  | 'businessRequestsCount'
  | 'contactInfoDto'
  | 'contractorRequestsInfo'
  | 'documents'
  | 'orders'
  | 'rating'
  | 'mainContact'
  | 'lastActivity'
  | 'contractStatus'
> &
  Partial<
    Pick<
      IContractor,
      | 'businessRequestsCount'
      | 'contactInfoDto'
      | 'contractorRequestsInfo'
      | 'documents'
      | 'orders'
      | 'rating'
    > & {
      mainContact?: TMainContactLite;
      lastActivityDate?: string;
      hasSignedContract: boolean;
    }
  >;

export type TContractorList = {
  countByStatus: {
    activeCount: number;
    lockedCount: number;
    noActivityCount: number;
  } | null;
  totalCount: number | null;
  items: {
    categoryId: number;
    organizations: TLiteOrganization[];
  }[];
};

export type TGetContractorsKanbanResponse = Omit<TContractorList, 'items'> & {
  items: {
    categoryId: number;
    organizationIds: number[];
  }[];
  organizations: TLiteOrganization[];
};

export type TGetContractorRequestsInfoResponse = {
  organizationId: number;
  totalCount: number;
  specificationSignedCount: number;
  inProgressCount: number;
  totalCost: number;
  ordersInWorkCounts: number;
  closedOrdersCounts: number;
  specificationPriceTotalCount: number;
}[];

export interface IContractorSobStatus {
  sobApproveStatus: string; // TODO: Добавить перечисление ESK_REQUESTED и тд
  sobComment: string;
}
