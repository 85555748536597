import { Box } from '@mui/material';
import { useGetCompanyBusinessRequestsQuery } from 'src/api/business-request/business-request';
import { NewTable, TableSkeleton } from 'src/components/ui';
import { TCompanyBusinessRequest } from 'src/models/bid';
import { Header } from './components';
import { columnsConfig } from './columns';
import styles from './all-company-requests-list.module.scss';

export const AllCompanyRequestsList = ({
  companyId,
}: {
  companyId: number;
}) => {
  const { data, isLoading, error } =
    useGetCompanyBusinessRequestsQuery(companyId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      width="100%"
      gap="20px"
      minWidth={0}>
      <Header totalCount={data?.length} />
      {(isLoading || !data) && !error ? (
        <TableSkeleton />
      ) : (
        !error && (
          <Box
            className={styles.scroll}
            sx={{
              borderRadius: '12px',
              // Todo: убрать костыль с maxHeight, когда у запроса появится пагинация
              maxHeight: '70vh !important',
              overflowY: 'scroll',
            }}>
            <NewTable<TCompanyBusinessRequest & { key: number }>
              className={styles.table}
              columns={columnsConfig}
              page={1}
              countPerPage={Infinity}
              data={data!.map((item) => ({
                ...item,
                key: item.number,
              }))}
            />
          </Box>
        )
      )}
    </Box>
  );
};
