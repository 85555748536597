import classNames from 'classnames';
import { forwardRef } from 'react';
import { Button as MuiButton } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { Loader } from 'src/components/ui/loader';

export type TButton = ButtonProps & {
  loading?: boolean;
  // Данный параметр нужен для совместимости со старой дизайн системой
  // старые иконки отображались в размере 16x16, новые 24x24
  applyNewIconSizing?: boolean;
};

export const Button = forwardRef<any, TButton>(
  (
    { loading, startIcon, variant, endIcon, applyNewIconSizing, ...props },
    ref
  ) => {
    const loadingByVariant = variant === 'text' ? 'loadingText' : 'loading';

    return (
      <MuiButton
        ref={ref}
        {...props}
        variant={loading ? loadingByVariant : variant}
        startIcon={
          loading ? (
            <div className={classNames(!applyNewIconSizing && 'old-svg-size')}>
              <Loader />
            </div>
          ) : (
            startIcon && (
              <div
                className={classNames(!applyNewIconSizing && 'old-svg-size')}>
                {startIcon}
              </div>
            )
          )
        }
        endIcon={
          endIcon && (
            <div className={classNames(!applyNewIconSizing && 'old-svg-size')}>
              {endIcon}
            </div>
          )
        }
      />
    );
  }
);
