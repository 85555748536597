import { Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { contactsApi } from 'src/api';
import {
  useAddSigningBasisMutation,
  useEditSigningBasisMutation,
} from 'src/api/admin/contacts';
import { useLazyDownloadDocumentQuery } from 'src/api/document';
import {
  AddIcon,
  ContractFile,
  Pencil,
  PencilEditTableItemIcon,
} from 'src/assets/icons';
import signingBasis from 'src/components/pages/admin/signing-basis/signing-basis';
import {
  TContactWithSigningBasis,
  TSigningBasis,
} from 'src/components/pages/company/contacts/types';
import {
  Autocomplete,
  Box,
  Button,
  DatePicker,
  Form,
  IconButton,
  Loader,
  OutlinedInput,
} from 'src/components/ui';
import { TFile } from 'src/components/ui/file-upload';
import { AttachFiles } from 'src/components/widgets/attach-files';
import { DocumentEntityTypes, DocumentTypes } from 'src/models';
import { TUser } from 'src/models/admin';
import { useAppDispatch } from 'src/store';
import { getErrorMessage } from 'src/store/utils';
import { date, required } from 'src/utils/form-rules';
import styles from './user-profile.module.scss';

type TProps = {
  isEditMode?: boolean;
  item?: TContactWithSigningBasis;
  selectedBasis?: TSigningBasis;
  isRequired?: boolean;
};

interface SignatureForm {
  signingBasisName: string;
  startDate: Date | null;
  endDate: Date | null;
  file: TFile[];
}

const DownloadButton = ({ id }: { id: number }) => {
  const [downloadDocument, { isFetching }] = useLazyDownloadDocumentQuery();

  return (
    <IconButton
      className={styles.fileIcon}
      onClick={(e) => {
        e.stopPropagation();
        downloadDocument(id);
      }}>
      {isFetching ? <Loader /> : <ContractFile />}
    </IconButton>
  );
};

export const UserSigningBasisFields = ({
  isEditMode,
  item,
  selectedBasis,
  isRequired,
}: TProps) => {
  const [downloadDocument, { isFetching }] = useLazyDownloadDocumentQuery();
  const [showAddSigningBasis, setShowAddSigningBasis] = useState(false);

  const [signingBasisToEdit, setSigningBasisToEdit] = useState<
    TSigningBasis | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  const [addSigningBasis, { isLoading: isAddSigningBasisLoading }] =
    useAddSigningBasisMutation();

  const [editSigningBasis, { isLoading: isEditSigningBasisLoading }] =
    useEditSigningBasisMutation();

  const formContainerRef = useRef<HTMLDivElement | null>(null);

  const form = useForm<SignatureForm>({
    defaultValues: {
      signingBasisName: '',
      file: [],
      startDate: null,
      endDate: null,
    },
  });

  useEffect(() => {
    if (signingBasisToEdit) {
      form.reset({
        signingBasisName: signingBasisToEdit.signingBasisName,
        file: signingBasisToEdit.document ? [signingBasisToEdit.document] : [],
        startDate: parseISO(signingBasisToEdit.startDate),
        endDate: parseISO(signingBasisToEdit.endDate),
      });
    } else {
      form.reset({
        signingBasisName: '',
        file: [],
        startDate: null,
        endDate: null,
      });
    }
  }, [signingBasisToEdit]);

  const handleEditSigningBasis = (signingBasisItem: TSigningBasis) => {
    setShowAddSigningBasis(true);
    setSigningBasisToEdit(signingBasisItem);
  };

  const handleSubmit = async (data: SignatureForm) => {
    try {
      const formData = new FormData();

      formData.append('userId', item!.userId.toString());

      formData.append('signingBasisName', data.signingBasisName);

      // Нужно отправлять без миллисекунд
      formData.append('startDate', data.startDate!.toISOString().split('.')[0]);

      formData.append('endDate', data.endDate!.toISOString().split('.')[0]);

      formData.append('isExpired', 'false');

      formData.append('isCurrent', 'true');

      if (data.file.length) {
        const file = data.file[0];

        formData.append('file', file!.file!);
      }

      if (signingBasisToEdit) {
        await editSigningBasis({
          signingBasisId: signingBasisToEdit.id,
          body: formData,
        }).unwrap();
      } else {
        await addSigningBasis(formData).unwrap();
      }

      form.reset();
      dispatch(contactsApi.util?.invalidateTags(['Contacts']));
      setShowAddSigningBasis(false);
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          e,
          'Произошла ошибка при добавлении права подписи'
        ),
      });
    }
  };

  return (
    <>
      {!isEditMode && isRequired && (
        <Typography
          fontWeight={500}
          fontSize="16px"
          sx={{ marginBottom: '10px' }}>
          Добавление права подписи
        </Typography>
      )}

      {isEditMode && (
        <Form.Item
          name="currentSigningBasis"
          label="Действует на основании"
          viewMode={!isEditMode}
          rules={{ required: isRequired && required }}>
          <Autocomplete<TSigningBasis>
            getOptionLabel={(option) => option.signingBasisName || ''}
            fetchOnEmptyInput
            options={item?.allSigningBasisList!}
            placeholder="Введите основание подписи"
            renderOption={(props, option) => (
              <Stack direction="row" component="li" {...props} key={option.id}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Typography variant="h4">
                    {option.signingBasisName}
                  </Typography>
                  <Typography color="secondary.light">
                    {`Действует: ${format(
                      new Date(option.startDate),
                      'dd.MM.yyyy'
                    )} - ${format(new Date(option.endDate), 'dd.MM.yyyy')}`}
                  </Typography>
                </Box>
                <Stack direction="row" gap="5px">
                  {option.documentId && (
                    <Box>
                      <DownloadButton id={option!.documentId!} />
                    </Box>
                  )}
                  <Box>
                    <IconButton
                      className={styles.fileIcon}
                      onClick={() => handleEditSigningBasis(option)}>
                      <Pencil />
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fetchApi={(value) =>
              Promise.resolve(
                item!.allSigningBasisList!.filter((basisItem) =>
                  basisItem.signingBasisName.includes(value)
                )
              )
            }
            inputProps={{
              classes: selectedBasis
                ? { root: styles.inputWrapper, input: styles.input }
                : undefined,
              endAdornment: selectedBasis && (
                <Stack gap="5px" direction="row">
                  {selectedBasis.document && (
                    <IconButton
                      className={styles.fileIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadDocument(selectedBasis!.documentId!);
                      }}>
                      {isFetching ? <Loader /> : <ContractFile />}
                    </IconButton>
                  )}
                  <Box>
                    <IconButton
                      className={styles.fileIcon}
                      onClick={() => handleEditSigningBasis(selectedBasis)}>
                      <Pencil />
                    </IconButton>
                  </Box>
                </Stack>
              ),
            }}
          />
        </Form.Item>
      )}
      {isEditMode && !signingBasisToEdit && (
        <Box mb="32px">
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => setShowAddSigningBasis(true)}>
            Добавить право подписи
          </Button>
        </Box>
      )}
      <Box
        display={!showAddSigningBasis ? 'none' : undefined}
        ref={formContainerRef}
      />
      {/* portal используется т.к. в html запрещены nested forms */}
      {showAddSigningBasis &&
        createPortal(
          <>
            <Typography
              fontWeight={500}
              fontSize="16px"
              sx={{ marginBottom: '10px' }}>
              {signingBasisToEdit
                ? 'Редактирование права подписи'
                : 'Добавление права подписи'}
            </Typography>
            <Form
              form={form}
              loading={isAddSigningBasisLoading || isEditSigningBasisLoading}
              stopPropagation
              submitButtonProps={{
                text: `${
                  signingBasisToEdit ? 'Обновить' : 'Добавить'
                } право подписи`,
              }}
              cancelBtnText="Отменить"
              onCancel={() => {
                setShowAddSigningBasis(false);
                setSigningBasisToEdit(undefined);
                form.reset();
              }}
              onSubmit={handleSubmit}>
              <Form.Item
                name="signingBasisName"
                label="Действует на основании"
                rules={{
                  required,
                }}>
                <OutlinedInput />
              </Form.Item>
              <Form.Item
                name="startDate"
                label="Дата начала действия подписи"
                rules={{
                  required,
                  validate: date,
                }}>
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="Дата конца действия подписи"
                rules={{
                  required,
                  validate: date,
                }}>
                <DatePicker minDate={new Date()} />
              </Form.Item>
              <Box>
                <AttachFiles
                  isEditMode
                  formItemName="file"
                  documentOptions={{
                    entityType: DocumentEntityTypes.SIGNING_BASIS,
                    documentType: DocumentTypes.SIGNING_BASIS,
                    entityId: 1,
                  }}
                  uploadFilesTitle={
                    <Typography variant="body2">
                      Действует на основании
                    </Typography>
                  }
                />
              </Box>
            </Form>
          </>,
          formContainerRef.current!
        )}
      {!isEditMode && isRequired && (
        <>
          <Form.Item
            name="signingBasisName"
            label="Действует на основании"
            rules={{
              required: isRequired && required,
            }}>
            <OutlinedInput />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Дата начала действия подписи"
            rules={{
              required: isRequired && required,
              validate: date,
            }}>
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="Дата конца действия подписи"
            rules={{
              required: isRequired && required,
              validate: date,
            }}>
            <DatePicker minDate={new Date()} />
          </Form.Item>
          <Box>
            <AttachFiles
              isEditMode
              formItemName="file"
              documentOptions={{
                entityType: DocumentEntityTypes.SIGNING_BASIS,
                documentType: DocumentTypes.SIGNING_BASIS,
                entityId: 1,
              }}
              uploadFilesTitle={
                <Typography variant="body2">Действует на основании</Typography>
              }
            />
          </Box>
        </>
      )}
    </>
  );
};
