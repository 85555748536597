import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import { Aim, CheckCircle20, Warning2, WarningSimple } from 'src/assets/icons';
import { Card, EmptyMessage, SvgIcon, Typography } from 'src/components/ui';
import { IKonturFocusData } from 'src/models';
import { FinanceAccordionItem, FinancialItem } from './components';

export const CompanyFinancesInfoBlock = ({
  scoringInfo,
  infoBlockClassName,
}: {
  scoringInfo: IKonturFocusData;
  infoBlockClassName?: string;
}) => {
  const getIcon = (statusValue: string): ReactElement => {
    switch (statusValue) {
      case 'WARNING':
        return <SvgIcon icon={WarningSimple} strokeColor="#ff7706" />;

      case 'ERROR':
        return <SvgIcon icon={Warning2} />;

      case 'RECOMMENDATION':
        return <SvgIcon icon={Aim} />;

      default:
        return <SvgIcon icon={CheckCircle20} strokeColor="#13B81A" />;
    }
  };

  const isHaveFinanceInfo = !!scoringInfo?.financeInfo;

  const isWarnings = scoringInfo?.warnings && !!scoringInfo.warnings.length;

  const warnings = isWarnings
    ? scoringInfo?.warnings.filter(
        (warning) => warning.name !== 'Существенные факты'
      )
    : [];

  const criticalWarningFacts = scoringInfo?.warnings?.filter(
    (warning) => warning.name === 'Существенные факты'
  );

  const getItemDynamic = (value: string) => ({
    value: value ? Math.abs(+value).toString() : '0',
    isIncreasing: +value >= 0,
  });

  const items = [
    {
      title: 'Выручка',
      value: scoringInfo?.financeInfo?.revenue || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.revenueDifference),
    },
    {
      title: 'Чистая прибыль',
      value: scoringInfo?.financeInfo?.netIncome || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.netIncomeDifference),
    },
    {
      title: 'Стоимость',
      value: scoringInfo?.financeInfo?.price || '0',
      dynamic: getItemDynamic(scoringInfo?.financeInfo?.priceDifference),
    },
    {
      title: 'Дебиторская задолженность',
      value: scoringInfo?.financeInfo?.accountReceivable || '0',
      dynamic: getItemDynamic(
        scoringInfo?.financeInfo?.accountReceivableDifference
      ),
    },
    {
      title: 'Кредиторская задолженность',
      value: scoringInfo?.financeInfo?.accountPayable || '0',
      dynamic: getItemDynamic(
        scoringInfo?.financeInfo?.accountPayableDifference
      ),
    },
  ];

  const printFinanceContent = () => {
    if (!isHaveFinanceInfo) {
      return !isWarnings ? (
        <EmptyMessage title="Информация по финансам неизвестна">
          По указанному ИНН компании информация по финансам не найдена
        </EmptyMessage>
      ) : null;
    }
    return (
      <Card
        title={`Основные показатели деятельности за ${
          scoringInfo?.financeInfo?.year || 'Неизвестный'
        } год`}
        className={infoBlockClassName}>
        <Stack direction="row" gap="24px" alignItems="flex-end">
          {items.map((item) => (
            <FinancialItem {...item} key={item.title} isRowDirection={false} />
          ))}
        </Stack>
        {criticalWarningFacts && criticalWarningFacts[0]?.items && (
          <Stack marginTop="24px">
            <Typography variant="h6" mb="16px">
              Обратите внимание
            </Typography>
            {criticalWarningFacts[0].items.map((item) => (
              <FinanceAccordionItem
                key={item.title}
                {...item}
                getIcon={getIcon}
              />
            ))}
          </Stack>
        )}
      </Card>
    );
  };

  return (
    <>
      {printFinanceContent()}
      {isWarnings &&
        warnings?.length > 0 &&
        warnings.map((warning) => (
          <Card
            key={warning.name}
            title={warning.name}
            className={infoBlockClassName}>
            {warning.items.map((item) => (
              <FinanceAccordionItem
                key={item.title}
                {...item}
                getIcon={getIcon}
              />
            ))}
          </Card>
        ))}
    </>
  );
};
