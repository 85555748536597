import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ActivityList } from 'src/components/pages/business-requests/components';
import { TCompanyBusinessRequest } from 'src/models/bid';
import { AuthService } from 'src/services';
import { theme } from 'src/theme';
import { getBidStatusColor } from 'src/utils/bids';
import styles from './request-info-column.module.scss';

export const RequestInfoColumn = ({
  item,
}: {
  item: TCompanyBusinessRequest;
}) => (
  <Link
    to={
      AuthService.isSpecificRole()
        ? `/all-requests/${item.businessRequestId}`
        : `/requests/${item.businessRequestId}`
    }>
    <Stack direction="column" gap="4px">
      <Stack flexDirection="row" gap="12px" alignItems="center">
        <Typography fontSize="12px">{`№${item.number} от ${format(
          new Date(item.createdAt),
          'dd.MM.yyyy'
        )}`}</Typography>
        <Stack flexDirection="row" gap="4px" alignItems="center">
          <div
            style={{
              height: 8,
              width: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <svg height="8" width="8">
              <circle
                height="8"
                width="8"
                cx="4"
                cy="4"
                r="3"
                fill={getBidStatusColor(item.applicationStatusHistory)}
              />
            </svg>
          </div>
          <Typography fontSize="12px" color={theme.palette.text.secondary}>
            {item.type === 'EXPECTED' ? 'Перспектива' : item.statusForKam}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        fontWeight={500}
        fontSize="12px"
        color={theme.palette.primary.dark}>
        {item.name}
      </Typography>
      {item.tagClassifierFistLevel.length !== 0 && (
        <ActivityList
          activityName={item.tagClassifierFistLevel}
          containerClassName={styles.tag}
        />
      )}
    </Stack>
  </Link>
);
